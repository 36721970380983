<template>
  <b-row>
    <b-col
      cols="12"
      md="6"
      lg="5"
    >
      <b-card class="cursor-pointer scroll-container d-flex flex-row overflow-auto gap-3 px-1">
        <div class="mb-1">
          <b-form-checkbox>
            <div class="fw-800">
              Phòng chờ VIP ga TP.Hồ Chí Minh
            </div>
          </b-form-checkbox>
        </div>
        <p>
          Sử dụng phòng chờ VIP ở ga Hà Nội trước 02h tàu xuất phát hoặc sau 02h tàu đến ga - giá dịch vụ là <span class="fw-700">20,000 VNĐ</span>
        </p>
      </b-card>

      <b-card class="cursor-pointer scroll-container d-flex flex-row overflow-auto gap-3 px-1">
        <div class="text-warning">
          <feather-icon
            icon="AlertCircleIcon"
            size="20"
          />
          <span class="fw-700 ml-25">Lưu ý</span>
        </div>
      </b-card>
    </b-col>

    <b-col
      cols="12"
      md="6"
      lg="7"
    >
      <b-card class="">
        <p class="fw-800">
          Khuyến mại cho chiều về
        </p>
        <b-row>
          <b-col class="fw-700">
            Thời gian áp dụng chương trình từ 05-09-23 đến 31-12-24. KHUYẾN MẠI MUA 3 TẶNG 1, MUA 6 TẶNG 2
          </b-col>
          <b-col>
            Giảm giá combo khi mua nhóm 4 người Giảm giá combo khi mua nhóm 6 người
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col class="fw-700">
            Thời gian áp dụng chương trình từ 16-01-24 đến 31-12-24. Khuyến mại khứ hồi tàu thống nhất
          </b-col>
          <b-col>
            Giảm 10% vé khứ hồi loại chỗ ngồi Giảm 15% vé khứ hồi loại chỗ giường nằm
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  BCard, BButton, BImgLazy, BImg, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImgLazy,
    BImg,
    BRow,
    BCol,
    BFormCheckbox,
  },
  props: {
    trainData: {
      type: Object,
      default: () => ({
        name: 'Tàu SE7',
        startTime: '06/10/2024 | 06:10',
        endTime: '07/10/2024 | 13:30',
        seatAvailable: 124,
      }),
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const checked = ref(false)

    return {
      checked,
    }
  },
}
</script>

<style lang="scss">

</style>
