var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "5"
    }
  }, [_c('b-card', {
    staticClass: "cursor-pointer scroll-container d-flex flex-row overflow-auto gap-3 px-1"
  }, [_c('div', {
    staticClass: "mb-1"
  }, [_c('b-form-checkbox', [_c('div', {
    staticClass: "fw-800"
  }, [_vm._v(" Phòng chờ VIP ga TP.Hồ Chí Minh ")])])], 1), _c('p', [_vm._v(" Sử dụng phòng chờ VIP ở ga Hà Nội trước 02h tàu xuất phát hoặc sau 02h tàu đến ga - giá dịch vụ là "), _c('span', {
    staticClass: "fw-700"
  }, [_vm._v("20,000 VNĐ")])])]), _c('b-card', {
    staticClass: "cursor-pointer scroll-container d-flex flex-row overflow-auto gap-3 px-1"
  }, [_c('div', {
    staticClass: "text-warning"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "AlertCircleIcon",
      "size": "20"
    }
  }), _c('span', {
    staticClass: "fw-700 ml-25"
  }, [_vm._v("Lưu ý")])], 1)])], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "7"
    }
  }, [_c('b-card', {}, [_c('p', {
    staticClass: "fw-800"
  }, [_vm._v(" Khuyến mại cho chiều về ")]), _c('b-row', [_c('b-col', {
    staticClass: "fw-700"
  }, [_vm._v(" Thời gian áp dụng chương trình từ 05-09-23 đến 31-12-24. KHUYẾN MẠI MUA 3 TẶNG 1, MUA 6 TẶNG 2 ")]), _c('b-col', [_vm._v(" Giảm giá combo khi mua nhóm 4 người Giảm giá combo khi mua nhóm 6 người ")])], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    staticClass: "fw-700"
  }, [_vm._v(" Thời gian áp dụng chương trình từ 16-01-24 đến 31-12-24. Khuyến mại khứ hồi tàu thống nhất ")]), _c('b-col', [_vm._v(" Giảm 10% vé khứ hồi loại chỗ ngồi Giảm 15% vé khứ hồi loại chỗ giường nằm ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }